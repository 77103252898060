@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
  from { 
    opacity: 0; 
    transform: translateY(20px); 
  }
  to { 
    opacity: 1; 
    transform: translateY(0); 
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out forwards;
}

/* Mobile styles for slide 7 */
@media screen and (max-width: 768px) {
  .slide-7 {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100vh !important;
  }
  
  .slide-7 img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    max-width: none !important;
    max-height: none !important;
  }

  .authenticity-image {
    object-position: 60% 45% !important;
  }

  .nfr-image {
    object-position: 50% 20% !important;
  }
} 