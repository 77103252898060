.password-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background: black !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 9999 !important;
  min-height: 100vh !important;
  min-height: -webkit-fill-available !important;
  padding: 20px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
}

.password-container {
  background: rgba(255, 255, 255, 0.02) !important;
  padding: clamp(40px, 6vw, 60px) !important;
  border-radius: 24px !important;
  backdrop-filter: blur(30px) !important;
  -webkit-backdrop-filter: blur(30px) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
  max-width: min(90%, 420px) !important;
  width: 100% !important;
  box-shadow: 
    0 25px 50px -12px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(220, 38, 38, 0.07) !important;
  animation: fadeIn 0.7s cubic-bezier(0.23, 1, 0.32, 1) !important;
  position: relative !important;
  overflow: hidden !important;
}

.password-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.05),
    transparent
  );
  animation: shimmer 2s infinite;
}

.logo-container {
  width: clamp(140px, 35vw, 200px);
  margin: 0 auto 40px;
  position: relative;
}

.logo-container img {
  width: 100%;
  height: auto;
  filter: drop-shadow(0 8px 16px rgba(0, 0, 0, 0.4));
}

.password-input {
  width: 100%;
  padding: 18px 20px;
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  color: white;
  font-size: clamp(16px, 2.2vw, 18px);
  margin-top: 28px;
  outline: none;
  transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.02em;
}

.password-input:focus {
  border-color: #dc2626;
  box-shadow: 
    0 0 0 3px rgba(220, 38, 38, 0.15),
    0 8px 16px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.05);
}

.password-title {
  color: white;
  font-size: clamp(26px, 3.5vw, 32px);
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;
  letter-spacing: -0.02em;
  line-height: 1.2;
}

.password-subtitle {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: clamp(15px, 2vw, 17px);
  line-height: 1.6;
  font-weight: 500;
  letter-spacing: 0.01em;
}

.error-message {
  color: #dc2626;
  font-size: 15px;
  margin-top: 16px;
  text-align: center;
  animation: shake 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  font-weight: 500;
  letter-spacing: 0.01em;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-15px) scale(0.98);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes shake {
  10%, 90% { transform: translateX(-1px); }
  20%, 80% { transform: translateX(2px); }
  30%, 50%, 70% { transform: translateX(-3px); }
  40%, 60% { transform: translateX(3px); }
}

.password-input::placeholder {
  color: rgba(255, 255, 255, 0.25);
  font-weight: 500;
}

/* Hover states */
.password-input:hover {
  border-color: rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.04);
}

/* Error state */
.password-input.error {
  border-color: #dc2626;
  animation: shake 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

/* Mobile optimizations */
@media (max-width: 480px) {
  .password-container {
    padding: 35px 25px;
  }
  
  .logo-container {
    margin-bottom: 30px;
  }
} 

/* Letter specific animations */
@keyframes paperFloat {
  0%, 100% { transform: rotate(-0.5deg); }
  50% { transform: rotate(-1deg); }
}

.letter-container {
  animation: paperFloat 10s ease-in-out infinite;
} 